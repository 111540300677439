import axios from 'axios';
// import Vue from "vue";

const HTTP = axios.create({
  baseURL: process.env.VUE_APP_API_HOST,
  headers: {
    'Accept': 'application/json'
  },
//   withCredentials: true
});

//Interceptors
import axiosInterceptorsSetup from '../../interceptors/axios.interceptors';
axiosInterceptorsSetup(HTTP);

export default {


    get({url, params,  baseURL}) {
        return HTTP.get(url,{
            baseURL:baseURL,
            params:params,
            headers:{
                
            }
        });
    },
    post({url, data,  baseURL}) {
        return HTTP.post(url, data, {
            baseURL:baseURL,            
            headers:{
                
            }
        });
    },
    put({url, data,  baseURL, id}) {
        return HTTP.put(`${url}/${id}`, data, {
            baseURL:baseURL,            
            headers:{
                
            }
        });
    },
    patch({url, data,  baseURL}) {
        return HTTP.patch(url, data, {
            baseURL:baseURL,            
            headers:{
                
            }
        });
    },
    async save({url, data,  baseURL, id}){
        
        if (id) {
            await this.put({ url, id, data, baseURL });
        } else {
            await this.post({ url, data, baseURL });
        }

        // Vue.swal({
        //     position: "top-end",
        //     icon: "success",
        //     title: "action executed successfully",
        //     showConfirmButton: false,
        //     timer: 2000
        // });
    }
};